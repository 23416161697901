import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import Button from "antd/lib/button";
import "antd/lib/button/style/index.css";

const btnStyle = {
  border: '2px solid white',
  padding: '14px 40px 35px',
  backgroundColor: '#6b191f',
  letterSpacing: '2px',
  color: '#fff',
  lineHeight: '1.499',
  fontSize: '16px',
  fontFamily: 'Nunito, sans-serif',
};

const Hero = props => {
  const { theme } = props;

  return (
    <React.Fragment>
      <section className="hero">
        <h1>Bay SIC 2019</h1>
        <h2>August 2-4</h2>
        <h3>hosted by <div>BLD SF Bay Area</div></h3>
        <Button type="primary" size="large" style={btnStyle} onClick={() => navigate('/register/')}>REGISTER</Button>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .hero {
          align-items: center;
          background-size: cover;
          color: ${theme.text.color.primary.inverse};
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          min-height: 100vh;
          height: 100px;
          padding: ${theme.space.inset.l};
          padding-top: ${theme.header.height.homepage};
          font-family: "Rock Salt";
        }

        h1 {
          text-align: center;
          font-size: ${theme.hero.h1.size};
          margin: ${theme.space.stack.l};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h1.lineHeight};
          letter-spacing: 0.08em;
          text-shadow: 3px 3px 11px rgba(0, 0, 0, 0.62);

          :global(strong) {
            position: relative;
          }
        }

        h2 {
          text-align: center;
          font-size: ${theme.hero.h2.size};
          margin: ${theme.space.stack.l};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h1.lineHeight};
          letter-spacing: 0.08em;
          text-shadow: 3px 3px 11px rgba(0, 0, 0, 0.62);

          :global(strong) {
            position: relative;
          }
        }

        h3 {
          text-align: center;
          font-size: ${theme.hero.h3.size};
          margin: ${theme.space.stack.l};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h3.lineHeight};
          letter-spacing: 0.08em;
          text-shadow: 3px 3px 11px rgba(0, 0, 0, 0.62);

          :global(strong) {
            position: relative;
          }
        }

        @keyframes buttonIconMove {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
          100% {
            transform: translateY(0);
          }
        }

        @from-width tablet {
          .hero {
          }

          h1 {
            max-width: 90%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
          }

          button {
            font-size: ${theme.font.size.l};
          }
        }

        @from-width desktop {
          .hero {
          }

          h1 {
            max-width: 80%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};
          }

          button {
            font-size: ${theme.font.size.xl};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Hero.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Hero;
